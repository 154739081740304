var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col h-full"},[_c('div',{staticClass:"flex-grow overflow-x-auto overflow-y-auto border border-gray-200 rounded-xl"},[_c('table',{staticClass:"min-w-full table-fixed bg-white"},[_c('thead',{staticClass:"border-b sticky top-0 z-50 bg-white"},[_c('tr',_vm._l((_vm.displayedHeaders),function(header,index){return _c('th',{key:`main-header-${index}`,staticClass:"h5 py-3 border-b border-gray-200 capitalize cursor-pointer hover:bg-neutral-0 whitespace-no-wrap",class:{
              'w-20': _vm.displayedHeaders.length > 5,
              'w-60 ': _vm.displayedHeaders.length < 4,
              'pl-10': _vm.hasSubRows,
              'pl-4': !_vm.hasSubRows,
            },on:{"click":function($event){return _vm.sortBy(header.key)}}},[_c('div',{staticClass:"flex items-center"},[_vm._v(" "+_vm._s(_vm.formatHeader(header.value))+" "),_c('DropdownArrow',{staticClass:"ml-2",attrs:{"direction":_vm.getSortDirection(header.key)}})],1),(_vm.hasSubRows && index === 0)?_c('div',{staticClass:"flex flex-row items-center gap-x-1"},[_c('IcArrowDownRight',{staticClass:"w-4 h-4 text-bb-neutral-gray"}),_c('p',{staticClass:"p2 pt-2"},[_vm._v(_vm._s(_vm.formatHeader(_vm.allSubRowHeaders[0].key)))])],1):_vm._e()])}),0)]),_c('tbody',[_vm._l((_vm.paginatedData),function(item,rowIndex){return [_c('tr',{key:rowIndex,class:[
              _vm.expandedRow === rowIndex ? 'bg-purple-0' : rowIndex % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
              item.sub_rows ? 'cursor-pointer' : '',
            ],on:{"click":function($event){item.sub_rows ? _vm.toggleRow(rowIndex) : null}}},_vm._l((_vm.displayedHeaders),function(header,colIndex){return _c('td',{key:`main-${rowIndex}-${colIndex}`,staticClass:"py-5 px-5 border-b border-gray-200 relative",class:{
                'pl-10': item.sub_rows,
                'pl-4': !item.sub_rows,
                'min-w-60': colIndex === 0,
                'min-w-auto': colIndex !== 0,
              }},[(item.sub_rows && colIndex === 0)?_c('IcChevron',{staticClass:"absolute left-2 cursor-pointer text-bb-disabled-gray",attrs:{"direction":_vm.expandedRow === rowIndex ? 'down' : 'right'}}):_vm._e(),(typeof item[header.key] === 'string')?[_c('NewTooltip',{attrs:{"direction":"bottom-start"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.lineBreaks(item[header.key]))}})]},proxy:true}],null,true)},[_c('span',{class:{
                      'line-clamp': !_vm.isExpanded(rowIndex, colIndex),
                    },domProps:{"innerHTML":_vm._s(_vm.lineBreaks(item[header.key]))}}),(_vm.shouldShowMoreButton(item[header.key]))?_c('p',{staticClass:"text-bb-brand-purple cursor-pointer",on:{"click":function($event){return _vm.toggleExpand(rowIndex, colIndex)}}},[_vm._v(" "+_vm._s(_vm.isExpanded(rowIndex, colIndex) ? 'Less' : 'More')+" ")]):_vm._e()])]:[_vm._v(" "+_vm._s(item[header.key])+" ")]],2)}),0),(_vm.expandedRow === rowIndex && item.sub_rows)?_vm._l((item.sub_rows),function(sub_row,subIndex){return _c('tr',{key:`sub-${rowIndex}-${subIndex}`,staticClass:"bg-white"},_vm._l((_vm.allSubRowHeaders),function(header,colIndex){return _c('td',{key:`sub-${rowIndex}-${subIndex}-${colIndex}`,staticClass:"py-3 px-10 max-w-42"},[_c('div',[_vm._v(" "+_vm._s(sub_row[header.key] !== undefined ? sub_row[header.key] : 'N/A')+" ")])])}),0)}):_vm._e()]})],2)])]),(_vm.totalRows > _vm.rowsPerPage)?_c('TablePagination',{staticClass:"py-3",attrs:{"limit":_vm.rowsPerPage,"page":_vm.currentPage,"max":_vm.totalRows,"disable-next":_vm.isNextDisabled,"disable-prev":_vm.isPrevDisabled},on:{"onChange":_vm.changePage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }