<template>
  <div
    key="subCheck"
    v-click-outside="handleClose"
    class="flex flex-col h-full max-h-screen overflow-hidden"
  >
    <div class="flex flex-row justify-between pt-7 ml-10 mr-20">
      <div class="flex flex-row gap-2">
        <Pill :color="'lightGray'">
          <template #text>
            <p class="capitalize">{{ formatCategory(subCheck.category) }}</p>
          </template>
        </Pill>
        <Pill
          v-if="subCheck.severity !== 'passed'"
          :color="severityItems.pillColor"
        >
          <template #text>
            <div class="flex items-center">
              <component
                :is="severityItems.severityIcon"
                :size="16"
                :class="severityItems.severityIconStyle"
                class="mx-auto"
              />
              <p
                class="font-bold mx-1"
                :class="severityItems.failedSubChecksStyle"
              >
                {{ subCheck.issues_count }}
              </p>
              <p class="font-medium pl-1 capitalize">{{ severityItems.severityText }}</p>
            </div>
          </template>
        </Pill>
      </div>
      <p class="font-medium text-bb-text-secondary">Audited on: {{ formattedCreatedAtDate }}</p>
    </div>

    <!-- Title -->
    <div class="flex flex-row justify-items-start mx-10 items-center border-b py-5">
      <h4 class="h4">{{ subCheck.title }}</h4>
    </div>

    <!-- Loading State -->
    <div
      v-if="isLoading"
      class="my-40 flex justify-center flex-grow"
    >
      <BrightbidLoader />
    </div>

    <!-- Content -->
    <div
      v-else-if="subCheck && !isLoading"
      class="flex flex-col flex-grow overflow-hidden"
    >
      <div
        v-if="subCheck.severity !== 'passed'"
        key="notRemovedNotRestored"
        class="mx-10 flex flex-col flex-grow overflow-hidden"
      >
        <div class="overflow-y-auto mt-7 flex-grow flex flex-col">
          <!-- Subtitle -->
          <div>
            <p
              class="p1"
              v-html="formattedSubtitle"
            ></p>
          </div>

          <!-- Recommendation -->
          <div
            v-if="subCheck.recommendation"
            class="w-full bg-purple-0 rounded-2xl px-3 sm:px-6 py-3 sm:py-4 mt-7"
          >
            <div class="flex flex-row items-start">
              <div class="bg-white rounded-lg p-2 flex shadow-drop-12 mr-3 sm:mr-5">
                <IcLightBulbOnOutline class="text-bb-brand-purple" />
              </div>
              <div class="flex flex-col gap-y-1">
                <p v-html="formattedRecommendation"></p>
                <a
                  class="font-bold text-bb-brand-purple flex flex-row items-center max-w-40"
                  target="_blank"
                  href="https://ads.google.com/aw/overview"
                >
                  <span>Go to Google Ads</span>
                  <IcChevron
                    direction="right"
                    class="ml-1 w-4 h-4"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Table Container -->
          <div class="my-6 flex-grow flex flex-col overflow-hidden">
            <SimpleTable
              :table-data="subCheck.result"
              class="flex-grow"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pill from '@/components/input/Pill.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcAlertOutline from 'vue-material-design-icons/AlertOutline'
import IcMinusCircleOutline from 'vue-material-design-icons/MinusCircleOutline'
import IcLightBulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcReplay from 'vue-material-design-icons/Replay'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcLightBulbOnOutline from 'vue-material-design-icons/LightbulbOnOutline'
import SimpleTable from '@/components/table/SimpleTable.vue'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '../loader/BrightbidLoader.vue'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuditReportModal',
  components: {
    Pill,
    MergeButtonRound,
    IcClock,
    IcAlertOutline,
    IcChevron,
    IcAlertCircle,
    IcMinusCircleOutline,
    IcCheckCircleOutline,
    IcLightBulbOutline,
    IcLightBulbOnOutline,
    dayjs,
    IcReplay,
    SimpleTable,
    Toast,
    BrightbidLoader,
  },
  props: {
    identification: {
      type: Number,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
    auditId: {
      type: String,
      required: true,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRemoved: false,
      isRestored: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auditCheck', { subCheck: 'getCheckDetails', checkError: 'getError' }),
    severityItems() {
      const severityMap = {
        warning: {
          pillColor: 'warningYellow',
          failedSubChecksStyle: 'text-bb-warning',
          severityIcon: 'IcAlertCircle',
          severityIconStyle: 'text-bb-warning',
          severityText: 'Warnings',
        },
        urgent: {
          pillColor: 'errorRed',
          failedSubChecksStyle: 'text-bb-red-error',
          severityIcon: 'IcAlertOutline',
          severityIconStyle: 'text-bb-red-error',
          severityText: 'Urgent Issues',
        },
        optimization: {
          pillColor: 'purple',
          failedSubChecksStyle: 'text-bb-brand-purple',
          severityIcon: 'IcLightBulbOutline',
          severityIconStyle: 'text-bb-brand-purple',
          severityText: 'Optimizations',
        },
        undefined: {
          pillColor: 'gray',
          failedSubChecksStyle: 'text-bb-grey',
          severityIcon: 'IcAlertCircle',
          severityIconStyle: 'text-bb-grey',
          severityText: 'Undefined',
        },
      }
      return severityMap[this.subCheck.severity] || severityMap.undefined
    },
    formattedCreatedAtDate() {
      return this.subCheck ? dayjs(this.subCheck.created_at).format('DD/MM/YYYY') : ''
    },
    formattedRecommendation() {
      return this.subCheck && this.subCheck.recommendation ? this.subCheck.recommendation.replace(/\n/g, '<br>') : ''
    },
    formattedSubtitle() {
      return this.subCheck && this.subCheck.subtitle ? this.subCheck.subtitle.replace(/\n/g, '<br>') : ''
    },
  },
  mounted() {
    this.fetchAuditCheckData()
  },
  methods: {
    ...mapActions('auditCheck', ['fetchCheckDetails', 'fetchExternalCheckDetails']),

    handleClose() {
      this.$emit('close')
    },
    async fetchAuditCheckData() {
      this.isLoading = true
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal) {
        await this.fetchExternalCheckDetails({
          customerId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      } else {
        await this.fetchCheckDetails({
          siteId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      }
      this.isLoading = false
    },
    formatCategory(category) {
      switch (category) {
        case 'ads':
          return 'Ads & Assets'
        default:
          return category
      }
    },
  },
}
</script>

<style scoped>
.modal-container {
  z-index: 9000;
}
</style>
