<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.89981 16.173C3.89981 14.7956 5.01635 13.6791 6.39367 13.6791H10.1002V17.3856C10.1002 18.7629 8.98365 19.8795 7.60633 19.8795H6.39367C5.01635 19.8795 3.89981 18.7629 3.89981 17.3856V16.173Z"
      stroke="currentColor"
      stroke-width="1.79962"
    />
    <path
      d="M20.0963 16.2013C20.0963 14.824 18.9797 13.7074 17.6024 13.7074H13.8959V17.4139C13.8959 18.7913 15.0124 19.9078 16.3898 19.9078H17.6024C18.9797 19.9078 20.0963 18.7913 20.0963 17.4139V16.2013Z"
      stroke="currentColor"
      stroke-width="1.79962"
    />
    <path
      d="M3.89981 7.60633C3.89981 8.98365 5.01635 10.1002 6.39367 10.1002H10.1002V6.39367C10.1002 5.01635 8.98365 3.89981 7.60633 3.89981H6.39367C5.01635 3.89981 3.89981 5.01635 3.89981 6.39367V7.60633Z"
      stroke="currentColor"
      stroke-width="1.79962"
    />
    <path
      d="M20.0963 7.60633C20.0963 8.98365 18.9797 10.1002 17.6024 10.1002H13.8959V6.39367C13.8959 5.01635 15.0124 3.89981 16.3898 3.89981H17.6024C18.9797 3.89981 20.0963 5.01635 20.0963 6.39367V7.60633Z"
      stroke="currentColor"
      stroke-width="1.79962"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcOutlinedSymbol',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
