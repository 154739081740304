<template>
  <button
    :class="buttonClasses"
    @click="handleClick"
  >
    <span class="flex items-center gap-2">
      <div :class="['p-1 rounded-full flex items-center justify-center', severityMap[severity].bgColor]">
        <slot name="icon"></slot>
      </div>
      <!-- Default slot for text -->
      <slot>Default Text</slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'FilterButton',
  props: {
    buttonType: {
      type: String,
      default: 'passed', // optimization, warning, urgent
    },
    severity: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      const baseClasses = [
        'p-3 shadow-bb-shadow rounded-lg pr-7 border border-white',
        'flex flex-row inline-flex gap-3 items-center justify-center',
      ]

      let stateBasedClasses = []

      switch (this.buttonType) {
        case 'passed':
          stateBasedClasses = ['focus:outline-none']
          if (this.isActive) {
            stateBasedClasses.push('border-success', 'border')
          }
          break
        case 'optimization':
          stateBasedClasses = ['focus:outline-none']
          if (this.isActive) {
            stateBasedClasses.push('border-bb-brand-purple', 'border')
          }
          break
        case 'warning':
          stateBasedClasses = ['focus:outline-none']
          if (this.isActive) {
            stateBasedClasses.push('border-bb-warning', 'border')
          }
          break
        case 'urgent':
          stateBasedClasses = ['focus:outline-none']
          if (this.isActive) {
            stateBasedClasses.push('border-bb-error', 'border')
          }
          break
      }

      return [...baseClasses, ...stateBasedClasses]
    },
    severityMap() {
      return {
        urgent: {
          bgColor: 'bg-bb-red-50',
          textColor: 'text-red-700',
        },
        warning: {
          bgColor: 'bg-bb-yellow-50',
          textColor: 'text-yellow-700',
        },
        optimization: {
          bgColor: 'bg-bb-secondary-purple',
          textColor: 'text-purple-700',
        },
        passed: {
          bgColor: 'bg-bb-success-bg',
          textColor: 'text-green-700',
        },
      }
    },
  },
  methods: {
    handleClick() {
      this.$emit('clicked')
    },
  },
}
</script>

<style scoped></style>
