<template>
  <div
    key="subCheck"
    v-click-outside="handleClose"
    class="flex flex-col h-full max-h-screen overflow-hidden"
  >
    <div class="flex flex-row justify-between pt-7 ml-10 mr-20 items-center">
      <div class="flex flex-row gap-2">
        <Pill :color="'lightGray'">
          <template #text>
            <p class="capitalize">{{ formatCategory(subCheck.category) }}</p>
          </template>
        </Pill>
      </div>
      <p class="font-medium text-bb-text-secondary">Audited on: {{ formattedCreatedAtDate }}</p>
    </div>

    <!-- Title -->
    <div class="flex flex-row justify-items-start mx-10 items-center border-b py-5">
      <h4 class="h4">{{ subCheck.title }}</h4>
    </div>

    <!-- Loading State -->
    <div
      v-if="isLoading"
      class="my-40 flex justify-center flex-grow"
    >
      <BrightbidLoader />
    </div>

    <!-- Content -->
    <div
      v-else-if="subCheck && !isLoading"
      class="flex flex-col flex-grow overflow-hidden"
    >
      <!-- Passed State -->
      <div class="mx-10 flex-grow flex flex-col justify-center items-center">
        <div class="bg-neutral-0 rounded-lg mt-3 flex flex-col justify-center items-center">
          <img
            src="/content/images/brightbid/robot-check.svg"
            alt=""
            class="w-32 pt-5"
          />
          <p
            class="py-4 xs:px-4 xs:text-center"
            v-html="formattedSubtitle"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pill from '@/components/input/Pill.vue'
import BrightbidLoader from '../loader/BrightbidLoader.vue'
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuditReportModal',
  components: {
    Pill,
    BrightbidLoader,
  },
  props: {
    identification: {
      type: Number,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
    auditId: {
      type: String,
      required: true,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auditCheck', { subCheck: 'getCheckDetails', checkError: 'getError' }),
    formattedCreatedAtDate() {
      return this.subCheck ? dayjs(this.subCheck.created_at).format('DD/MM/YYYY') : ''
    },
    formattedSubtitle() {
      return this.subCheck && this.subCheck.subtitle ? this.subCheck.subtitle.replace(/\n/g, '<br>') : ''
    },
  },
  mounted() {
    this.fetchAuditCheckData()
  },
  methods: {
    ...mapActions('auditCheck', ['fetchCheckDetails', 'fetchExternalCheckDetails']),

    handleClose() {
      this.$emit('close')
    },
    async fetchAuditCheckData() {
      this.isLoading = true
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal) {
        await this.fetchExternalCheckDetails({
          customerId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      } else {
        await this.fetchCheckDetails({
          siteId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      }
      this.isLoading = false
    },
    formatCategory(category) {
      switch (category) {
        case 'ads':
          return 'Ads & Assets'
        default:
          return category
      }
    },
  },
}
</script>

<style scoped>
.modal-container {
  z-index: 9000;
}
</style>
