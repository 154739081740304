<template>
  <div :id="donutId"></div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'DonutChart',
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
      validator: value => ['small', 'large'].includes(value),
    },
  },
  computed: {
    fontSize() {
      return this.size === 'large' ? '48px' : '36px'
    },
  },
  data() {
    return {
      donutId: `donut-${Math.random().toString(36).substr(2, 9)}`,
    }
  },
  mounted() {
    this.createDonutSvg()
  },
  methods: {
    createDonutSvg() {
      const sizes = {
        small: { width: 150, height: 150 },
        large: { width: 200, height: 200 },
      }

      const { width, height } = sizes[this.size]
      const radius = Math.min(width, height) / 2
      const thickness = 16

      const svg = d3
        .select(`#${this.donutId}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`)

      // Determine gradient based on percentage
      let gradientColors, gradientAngle
      if (this.percentage <= 0.24) {
        gradientColors = [
          { offset: '0%', color: '#FF0000' },
          { offset: '100%', color: '#FF0000' },
        ]
        gradientAngle = [0, 0, 100, 0] // Solid red (horizontal)
      } else if (this.percentage <= 0.49) {
        gradientColors = [
          { offset: '1.57%', color: '#F2E14A' },
          { offset: '98.33%', color: '#F23F44' },
        ]
        gradientAngle = [84, 100, 100, 0] // 192.85 degrees
      } else if (this.percentage <= 0.74) {
        gradientColors = [
          { offset: '12.88%', color: '#9ECF14' },
          { offset: '97.6%', color: '#FFEB38' },
        ]
        gradientAngle = [0, 100, 100, 0] // 214.43 degrees
      } else if (this.percentage <= 0.89) {
        gradientColors = [
          { offset: '5.12%', color: '#18B070' },
          { offset: '97.34%', color: '#C0E13C' },
        ]
        gradientAngle = [0, 100, 100, 0] // 219.63 degrees
      } else {
        gradientColors = [
          { offset: '0%', color: '#18B070' },
          { offset: '100%', color: '#18B070' },
        ]
        gradientAngle = [0, 0, 100, 0] // Solid green (horizontal)
      }

      // Define gradient
      const gradient = svg
        .append('defs')
        .append('linearGradient')
        .attr('id', `gradient-${this.donutId}`) // Use dynamic gradient ID
        .attr('x1', `${gradientAngle[0]}%`)
        .attr('y1', `${gradientAngle[1]}%`)
        .attr('x2', `${gradientAngle[2]}%`)
        .attr('y2', `${gradientAngle[3]}%`)

      gradientColors.forEach(colorStop => {
        gradient.append('stop').attr('offset', colorStop.offset).attr('stop-color', colorStop.color)
      })

      // Background arc
      const backgroundArc = d3
        .arc()
        .innerRadius(radius - thickness)
        .outerRadius(radius)
        .startAngle(0)
        .endAngle(2 * Math.PI)

      svg.append('path').attr('d', backgroundArc).attr('fill', '#e6e6e6')

      // Foreground arc with gradient and rounded ends
      const foregroundArc = d3
        .arc()
        .innerRadius(radius - thickness)
        .outerRadius(radius)
        .startAngle(0)
        .endAngle(this.percentage * 2 * Math.PI)
        .cornerRadius(thickness / 2) // Apply rounded corners

      svg.append('path').attr('d', foregroundArc).attr('fill', `url(#gradient-${this.donutId})`)

      // Text in the center
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '0.35em')
        .attr('font-size', this.fontSize) // Use computed font size
        .attr('font-weight', '700')
        .attr('fill', '#3E4552')
        .text(this.percentage ? `${Math.round(this.percentage * 100)}%` : '- %')
    },
  },
}
</script>

<style scoped>
div[id^='donut'] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
