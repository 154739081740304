<template>
  <div
    class="py-1 px-6 p3 my-auto rounded-2xl"
    :class="classes"
  >
    <div class="font-bold px-0.5 flex flex-row gap-1">
      <slot name="text">Default</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pill',
  props: {
    color: {
      type: String,
      required: false,
      default: 'gray',
      validator: val =>
        ['gray', 'red', 'lightGray', 'errorRed', 'warningYellow', 'successGreen', 'purple'].includes(val),
    },
  },
  computed: {
    classes() {
      return {
        'bg-neutral-50 text-bb-text-secondary': this.color === 'gray',
        'bg-bb-dull-red text-white': this.color === 'red',
        'bg-neutral-0 text-bb-text-secondary': this.color === 'lightGray',
        'bg-bb-red-50 text-bb-text-default': this.color === 'errorRed',
        'bg-bb-yellow-50 text-bb-text-default': this.color === 'warningYellow',
        'bg-bb-success-bg': this.color === 'successGreen',
        'bg-bb-secondary-purple': this.color === 'purple',
      }
    },
  },
}
</script>
